import React from "react"

import {Link} from "gatsby";
import Layout from "../components/layout"
import Logo from "../components/images/logo"
import LogoMobile from "../components/images/logo-mobile"
import Seo from "../components/seo"

import Typist from 'react-typist'

const IndexPage = (props) => (
  <Layout>
      <Logo title="ps:dreizehn GmbH" />
      <LogoMobile title="ps:dreizehn GmbH" />
        <Seo title="home" />
        <div className="index--container">
            <div className="index--claim-wrapper">

                <span className="index--claim">

                    Hey, wir sind <Typist cursor={{blink: true}}><h1>ps:dreizehn</h1></Typist>. Wir machen Onlineshops mit Shopware für dich :-). Schau <Link to="/about/">hier</Link>, was wir zu bieten haben oder <Link to="/kontakt/">meld dich einfach!</Link>

                </span>

            </div>
        </div>
  </Layout>
)

export default IndexPage
